import React from "react";
import { Link } from "gatsby";
import * as styles from "./posts.module.css";

const PostLink = ({ post }) => (
  <div className={styles.lang}>
    <Link
      to={post.frontmatter.path}
      className={`${styles.shadow} ${styles.card}`}
    >
      {/* <article className={styles.card}> */}
      {/* <Link to={post.frontmatter.path} className={styles.thumb}> */}
      <div className={styles.thumb}>
        {!!post.frontmatter.thumbnail && (
          <img
            src={post.frontmatter.thumbnail}
            alt={post.frontmatter.title + "- Featured Shot"}
          />
        )}
      </div>
      {/* </Link> */}
      <header>
        <h2 className={styles.post_title}>
          {/* <Link to={post.frontmatter.path} className={styles.post_link}> */}
          {post.frontmatter.title}
          {/* </Link> */}
        </h2>
        <div className={styles.post_meta}>{post.frontmatter.date}</div>
        <p className={styles.toread}>{post.timeToRead} min read</p>

        {/* <Link to={`ml${post.frontmatter.path}`} className={styles.shadow}>
          Malayalam
        </Link> */}
      </header>
      {/* </article> */}
    </Link>
  </div>
);
export const TagLink = ({ post }) => (
  <article className={`${styles.card} ${styles.tagWidth}`}>
    <header>
      <h2>
        <Link to={post.frontmatter.path} className={styles.post_link}>
          {post.frontmatter.title}
        </Link>
      </h2>
      <div className={styles.post_meta}>{post.frontmatter.date}</div>
      <p className={styles.toread}>{post.timeToRead} min read</p>
    </header>
  </article>
);
export default PostLink;
