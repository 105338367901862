import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout/layout";
import PostLink from "../components/PostLinks/post-links";
import * as styles from "./styles.module.css";
import Seo from "../components/Seo/seo";
import "@fontsource/lato";
import { kebabCase } from "lodash-es";

const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
    tag,
  },
}) => {
  const compare = (a, b) => {
    if (a.totalCount < b.totalCount) {
      return 1;
    }
    if (a.totalCount > b.totalCount) {
      return -1;
    }
    return 0;
  };
  const Posts = edges
    .filter((edge) => !!edge.node.frontmatter.date)
    .map((edge) => <PostLink key={edge.node.id} post={edge.node} />);
  return (
    <Layout>
      {site.siteMetadata && edges && (
        <>
          <Seo
            title={site.siteMetadata.title}
            description={site.siteMetadata.description}
          />
          {/* <h2 className={styles.heading}>Blog Posts</h2> */}
          <div className={styles.container}>{Posts}</div>
          {tag.group && (
            <>
              <h4>Top Tags</h4>
              <div className={styles.tag}>
                {tag.group
                  .sort(compare)
                  .slice(0, 10)
                  .map((tag) => (
                    // <p  key={tag.fieldValue}>
                    //   {tag.fieldValue}
                    // </p>
                    <Link
                      key={tag.fieldValue}
                      className={styles.tagName}
                      to={`/tags/${kebabCase(tag.fieldValue)}/`}
                    >
                      {tag.fieldValue}
                    </Link>
                  ))}
              </div>
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { language: { eq: "en" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          timeToRead
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
          }
        }
      }
    }
    tag: allMarkdownRemark(
      filter: { frontmatter: { language: { eq: "en" } } }
      limit: 1000
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
